<script>
import Layout from '../layouts/main.vue'
import PageHeader from '@/components/page-header'

export default {
    components: { Layout, PageHeader },
}
</script>
    
<template>
    <Layout>
        <PageHeader :title="title" :items="items" />

      <div class="container text-center">
        <img src="https://cdn-icons-png.flaticon.com/512/576/576997.png" alt="under construction image" width="300">
        <p class="h2 text-center fw-bold mt-3 text-danger">UNDER CONSTRUCTION</p>
      </div>
</Layout>
</template>
